import React from 'react';
import styled from 'styled-components';
import { H1, ParagraphRegular } from '../../shared/Typography/Typography'
import { device } from '../../shared/styles/breakpoints';
import { ButtonInternalLink } from '../../shared/Link/Link';
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledHeroTextWrapper = styled.div`
  max-width: 480px;
  padding: ${({ theme }) => `${theme.spaces[64]} ${theme.spaces[16]}`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${device.xl} {
    padding: ${({ theme }) => `${theme.spaces[64]} 0`};
  }
`

const StyledHeroTextContainer = styled.div`
  @media ${device.xl} {
    justify-self: end;
    max-width: 590px;
    width: 100%;
  }
`
const StyledHeroTitle = styled(H1)`
  white-space: pre-wrap;
  margin: ${({ theme, isEn }) => `0 0 ${theme.spaces[16]}`};

  > span {
    color: ${({ theme }) => theme.colors.yellow};
    display: block;
  }
`
const StyledHeroText = styled(ParagraphRegular)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[32]}`};
`

export default function HeroText () {
  const { t } = useTranslation()

  return (
    <StyledHeroTextContainer>
      <StyledHeroTextWrapper>
        <StyledHeroTitle>
          {t("Cześć, jesteśmy")}
          <span>Frenzy!</span>
        </StyledHeroTitle>
        <StyledHeroText>{t("heroText")}</StyledHeroText>
        <ButtonInternalLink sufixIcon to="/realizations">{t("Nasze realizacje")}</ButtonInternalLink>
      </StyledHeroTextWrapper>
    </StyledHeroTextContainer>
  )
}
