import aliorBankImg from "../../../images/clients/Alior.png"
import atmGroupImg from "../../../images/clients/ATM.png"
import benqImg from "../../../images/clients/BenQ.png"
import blizzardImg from "../../../images/clients/Blizzard.png"
import cdpImg from "../../../images/clients/CDP.png"
import digitalmotorsImg from "../../../images/clients/Digitalmotors.png"
import solServiceImg from "../../../images/clients/Sol.png"
import ekstraklasaImg from "../../../images/clients/Ekstraklasa.png"
import ubisoftImg from "../../../images/clients/Ubisoft.png"
import epicGamesImg from "../../../images/clients/Epicgames.png"
import xkomImg from "../../../images/clients/xkom.png"
import polsatBoxImg from "../../../images/clients/PolsatBox.png"
import riotGamesImg from "../../../images/clients/Riot.png"
import redbullImg from "../../../images/clients/Redbull.png"
import eslImg from "../../../images/clients/ESL.png"
import grupaZywiecImg from "../../../images/clients/GrupaZywiec.png"
import interiaImg from "../../../images/clients/interia.png"
import nvidiaImg from "../../../images/clients/nvidia.png"
import cenegaImg from "../../../images/clients/Cenega.png"
import eaImg from "../../../images/clients/EA.png"
import krakowskiParkImg from "../../../images/clients/KrakowskiPark.png"
import pleImg from "../../../images/clients/PLE.png"
import plusImg from "../../../images/clients/plus.png"
import rtvImg from "../../../images/clients/rtv-euro-agd.png"

export const clientsList = [
  {
    src: polsatBoxImg,
    alt: "Polsat box"
  },
  {
    src: interiaImg,
    alt: "Intria"
  },
  {
    src: plusImg,
    alt: 'Plus'
  },
  {
    src: solServiceImg,
    alt: "Sol Service"
  },
  {
    src: blizzardImg,
    alt: "Blizzard"
  },
  {
    src: cdpImg,
    alt: "CD Projekt Red"
  },
  {
    src: ubisoftImg,
    alt: "ubisoft"
  },
  {
    src: epicGamesImg,
    alt: "Epic Games"
  },
  {
    src: riotGamesImg,
    alt: "Riot Games"
  },
  {
    src: cenegaImg,
    alt: "Cenega"
  },
  {
    src: eaImg,
    alt: "EA Games"
  },
  {
    src: benqImg,
    alt: "Benq"
  },
  {
    src: xkomImg,
    alt: "X-kom"
  },
  {
    src: rtvImg,
    alt: 'RTV euro agd'
  },
  {
    src: digitalmotorsImg,
    alt: "Digitial Motors"
  },
  {
    src: ekstraklasaImg,
    alt: "PKO Ekstraliga"
  },
  {
    src: nvidiaImg,
    alt: "NVidia"
  },
  {
    src: redbullImg,
    alt: "Redbull"
  },
  {
    src: eslImg,
    alt: "ESL"
  },
  {
    src: grupaZywiecImg,
    alt: "Grupa Żywiec"
  },
  {
    src: krakowskiParkImg,
    alt: "Krakowski Park"
  },
  {
    src: pleImg,
    alt: "PLE"
  },
  {
    src: aliorBankImg,
    alt: "Alior Bank"
  },
  {
    src: atmGroupImg,
    alt: "ATM Group"
  },
]
