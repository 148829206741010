import React from 'react';

export function FileIcon () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.693 3.601 8.403 1H21v22H3V6.186l2.693-2.585Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="10" y="7" width="4" height="4" rx="1" fill="#fff" />
    </svg>
  )
}
