import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import StaffMembersGrid from "../components/MainPage/StaffMembers/StaffMembersGrid"
import ClientSection from "../components/MainPage/ClientsSection/ClientSection"
import HeroSection from "../components/MainPage/HeroSection/HeroSection"
import RealizationsSection from "../components/MainPage/RealizationsSection/RealizationsSection"
import ServicesSection from "../components/MainPage/ServicesSection/ServicesSection"
import styled from "styled-components"
import { device } from "../components/shared/styles/breakpoints"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledIndexBg = styled.div`
  @media ${device.lg} {
    position: absolute;
    top: ${({ theme }) => `-${theme.sizes.header}`};
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 50%;
      top: 0;
      bottom: 0;
      background-color: ${({ theme }) => `${theme.colors.lightGray}80`};
      z-index: -1;
    }
  }
`

export default function IndexPage ({ data }) {
  const realizationsData = data.allContentfulRealizations.nodes
  const { t } = useTranslation()

  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])

  return (
    <Layout isMainPage>
      <main>
        <StyledIndexBg />
        <Seo title={t("Strona główna")} />
        <HeroSection />
        <RealizationsSection realizationsList={realizationsData} />
        <ServicesSection data-aos="fade-in" />
        <ClientSection data-aos="fade-in" />
        <StaffMembersGrid />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulRealizations(
      filter: { showInMainpage: { eq: true }, node_locale: { eq: $language } }
      sort: { order: ASC, fields: orderInMainpageSlider }
    ) {
      nodes {
        contentful_id
        slug
        title
        type
        orderInMainpageSlider
        boldDescription {
          boldDescription
        }
        mainImage {
          file {
            url
          }
          title
        }
        partnerImg {
          file {
            url
          }
          title
        }
      }
    }
  }
`
