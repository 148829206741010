import React, { useState, useEffect, useCallback } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { H2, Overline } from "../../shared/Typography/Typography"
import GridContainer from "../../Layout/GridContainer"
import ClientSliderItem from "./ClientSliderItem"
import { clientsList } from "./clientsList"
import { chunk, debounce } from "lodash"
import { device, sizes } from "../../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledClientSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spaces[80]};
  @media ${device.lg} {
    margin-bottom: ${({ theme }) => theme.spaces[64]};
  }
`

const StyledOverline = styled(Overline)`
  display: block;
  text-align: left;
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};

  @media ${device.lg} {
    text-align: center;
  }
`

const StyledTitle = styled(H2)`
  display: block;
  text-align: left;
  margin: 0;
  @media ${device.lg} {
    text-align: center;
  }
`

const StyledSliderWrapper = styled(Slider)`
  margin-top: ${({ theme }) => theme.spaces[40]};
  @media ${device.lg} {
    margin-top: ${({ theme }) => theme.spaces[52]};
  }
`

const StyledPaginDot = styled.div`
  border-radius: 100%;
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.midGray};
  transition: ${({ theme }) => theme.transition};
`

const StyledSliderPagin = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spaces[16]};
  bottom: -20px !important;
  > ul {
    padding: 0;
    list-style: none;
    li {
      width: 8px;
      height: 8px;
      &.slick-active > * {
        background-color: ${({ theme }) => theme.colors.darkGray};
      }
    }
  }
`

export default function ClientSection({ ...props }) {
  const [clients, setClients] = useState([])
  const { t } = useTranslation()

  const handleResize = useCallback(
    debounce(() => {
      if (window.innerWidth < sizes.sm) {
        setClients(chunk(clientsList, 4))
      } else if (window.innerWidth > sizes.sm && window.innerWidth < sizes.md) {
        setClients(chunk(clientsList, 6))
      } else if (window.innerWidth > sizes.md && window.innerWidth < sizes.lg) {
        setClients(chunk(clientsList, 8))
      } else {
        setClients(chunk(clientsList, 12))
      }
    }, 300),
    []
  )

  useEffect(() => {
    if (clients.length === 0) {
      handleResize()
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <StyledSliderPagin>
        <ul>{dots}</ul>
      </StyledSliderPagin>
    ),
    customPaging: () => <StyledPaginDot />,
  }

  return (
    <StyledClientSection {...props}>
      <GridContainer>
        <StyledOverline>{t("Nasi klienci")}</StyledOverline>
        <StyledTitle>{t("Oni nam zaufali")}</StyledTitle>
        <StyledSliderWrapper>
          <Slider {...settings}>
            {clients.map((client, key) => (
              <div key={key}>
                <ClientSliderItem clientsLogos={client} />
              </div>
            ))}
          </Slider>
        </StyledSliderWrapper>
      </GridContainer>
    </StyledClientSection>
  )
}
