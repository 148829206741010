import React, { useState } from "react"
import styled from "styled-components"
import {
  H2,
  Overline,
  ParagraphRegular,
} from "../../shared/Typography/Typography"
import { ChevronLeftIcon } from "../../shared/Icons/ChevronLeftIcon"
import { ChevronRightIcon } from "../../shared/Icons/ChevronRightIcon"
import { device } from "../../shared/styles/breakpoints"
import { ButtonInternalLink } from "../../shared/Link/Link"
import { useTranslation } from "gatsby-plugin-react-i18next"
import times from "lodash/times"

const StyledRealizationsSection = styled.section`
  @media ${device.lg} {
    background-color: ${({ theme }) => theme.colors.yellow};
    padding-bottom: ${({ theme, shouldRenderSliderElements }) =>
      shouldRenderSliderElements ? theme.spaces[100] : 0};
  }
`

const StyledRealizationContent = styled.div`
  @media ${device.lg} {
    max-width: 1180px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 578px;
    position: relative;
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => `-${theme.spaces[4]}`};
  height: 367px;

  @media ${device.lg} {
    height: 100%;
  }
`

const StyledSlideImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const StyledTextContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spaces[96]} ${theme.spaces[16]} ${theme.spaces[64]}`};
  background-color: ${({ theme }) => theme.colors.yellow};
  position: relative;
  box-sizing: border-box;
  @media ${device.lg} {
    padding: ${({ theme }) =>
      `${theme.spaces[160]} 0 ${theme.spaces[180]} ${theme.spaces[80]}`};
    height: auto;
  }
  @media ${device.xl} {
    padding: ${({ theme }) =>
      `${theme.spaces[160]} 0 ${theme.spaces[180]} ${theme.spaces[120]}`};
  }
`

const StyledTextWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  top: ${({ theme }) => theme.spaces[96]};
  left: ${({ theme }) => theme.spaces[16]};
  right: ${({ theme }) => theme.spaces[16]};
  bottom: ${({ theme }) => theme.spaces[64]};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  @media ${device.lg} {
    position: absolute;
    display: block;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    max-width: 380px;
  }
`

const StyledOverline = styled(Overline)`
  display: block;
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`

const StyledTitle = styled(H2)`
  display: block;
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`

const StyledText = styled(ParagraphRegular)`
  display: block;
  margin: ${({ theme }) => `0 0 ${theme.spaces[32]}`};
`

const StyledControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: -28px;
  left: 0;
  display: flex;
  z-index: 1;
  @media ${device.lg} {
    margin-bottom: -40px;
  }
`

const StyledControl = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: ${({ theme }) => theme.transition};
  border: none;
  box-shadow: none;
  &:hover {
    cursor: pointer;
  }

  @media ${device.lg} {
    width: 80px;
    height: 80px;
  }
`

const StyledLeftControl = styled(StyledControl)`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.midGray};
  }
`

const StyledRightControl = styled(StyledControl)`
  color: ${({ theme }) => theme.colors.yellow};
  background-color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: ${({ theme }) => theme.colors.black300};
  }
`

const StyledPaginWrapper = styled.div`
  display: none;
  @media ${device.lg} {
    display: flex;
    position: absolute;
    left: 50%;
    width: 50%;
    top: 100%;
  }
`

const StyledPaginItem = styled.div`
  width: 196px;
  height: 196px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGray};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transition};
  box-shadow: inset 0px 0px 0px 0px ${({ theme }) => theme.colors.lightGray};

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 4px ${({ theme }) => theme.colors.lightGray};
  }
`

const StyledButton = styled(ButtonInternalLink)`
  display: inline-block;
`

export default function RealizationsSection({ realizationsList, ...props }) {
  const slidesLength = realizationsList.length
  const shouldRenderSliderElements = realizationsList.length > 1
  const [activeSlide, setActiveSlide] = useState(0)
  const { t } = useTranslation()

  const handleNextClick = () => {
    if (activeSlide !== slidesLength - 1) {
      setActiveSlide(prevState => prevState + 1)
    } else {
      setActiveSlide(0)
    }
  }

  const handlePrevClick = () => {
    if (activeSlide !== 0) {
      setActiveSlide(prevState => prevState - 1)
    } else {
      setActiveSlide(
        realizationsList[slidesLength - 1].orderInMainpageSlider - 1
      )
    }
  }

  const renderPaginItem = index => {
    const paddingIndex = (activeSlide + index) % slidesLength
    const findPaginElement = realizationsList.find(
      el => el.orderInMainpageSlider - 1 === paddingIndex
    )
    return (
      <StyledPaginItem onClick={() => setActiveSlide(paddingIndex)} key={index}>
        <img
          src={findPaginElement?.partnerImg.file.url}
          alt={findPaginElement?.partnerImg.title}
        />
      </StyledPaginItem>
    )
  }
  return (
    <StyledRealizationsSection
      shouldRenderSliderElements={shouldRenderSliderElements}
      data-aos="fade-in"
    >
      <StyledRealizationContent>
        <StyledImageWrapper>
          {realizationsList.map(el => (
            <StyledSlideImage
              isActive={activeSlide === el.orderInMainpageSlider - 1}
              key={el.contentful_id}
            >
              <StyledImage
                src={el.mainImage.file.url}
                alt={el.mainImage.title}
              />
            </StyledSlideImage>
          ))}
          {shouldRenderSliderElements && (
            <StyledControlsWrapper>
              <StyledLeftControl onClick={handlePrevClick}>
                <ChevronLeftIcon />
              </StyledLeftControl>
              <StyledRightControl onClick={handleNextClick}>
                <ChevronRightIcon />
              </StyledRightControl>
            </StyledControlsWrapper>
          )}
        </StyledImageWrapper>
        <StyledTextContainer>
          {realizationsList.map(el => (
            <StyledTextWrapper
              key={el.contentful_id}
              isActive={activeSlide === el.orderInMainpageSlider - 1}
            >
              <StyledOverline>{t("Nasze realizacje")}</StyledOverline>
              <StyledTitle>{el.title}</StyledTitle>
              <StyledText>{el.boldDescription.boldDescription}</StyledText>
              <StyledButton
                sufixIcon
                color="secondary"
                to={`/realizations/${el.slug}`}
              >
                {t("Dowiedz się więcej")}
              </StyledButton>
            </StyledTextWrapper>
          ))}
        </StyledTextContainer>

        <StyledPaginWrapper>
          {times(slidesLength - 1, i => renderPaginItem(i + 1))}
        </StyledPaginWrapper>
      </StyledRealizationContent>
    </StyledRealizationsSection>
  )
}
