import React from "react"
import styled from "styled-components"
import {
  H2,
  ParagraphRegular,
  Overline,
} from "../../shared/Typography/Typography"
import ServiceBox from "./ServiceBox"
import { EquipmentIcon } from "../../shared/Icons/EquipmentIcon"
import { TvIcon } from "../../shared/Icons/TVIcon"
import { EventsIcon } from "../../shared/Icons/EventsIcon"
import { EsportIcon } from "../../shared/Icons/EsportIcon"
import { VideoIcon } from "../../shared/Icons/VideoIcon"
import { MarketingIcon } from "../../shared/Icons/MarketingIcon"
import GridContainer from "../../Layout/GridContainer"
import { device } from "../../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledServicesContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spaces[64]} 0 0 `};
  margin: 0 auto;
  max-width: 360px;
  @media ${device.sm} {
    max-width: 480px;
  }
  @media ${device.md} {
    max-width: 100%;
    margin: ${({ theme }) => `${theme.spaces[64]} 0 ${theme.spaces[64]}`};
  }
`

const StyledServicesBox = styled.div`
  margin: ${({ theme }) => `${theme.spaces[40]} 0 ${theme.spaces[72]}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[48]};
  @media ${device.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spaces[32]};
  }
  @media ${device.xl} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spaces[20]};
  }
`
const StyledSlogan = styled(H2)`
  margin: 0;
  @media ${device.sm} {
    white-space: pre-wrap;
  }
`
const StyledText = styled(ParagraphRegular)`
  margin: ${({ theme }) => `${theme.spaces[8]} 0 0 `};
`

const StyledTextWrapper = styled.div`
  max-width: 450px;
`
const StyledOverline = styled(Overline)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`

export default function ServicesSection({ ...props }) {
  const { t } = useTranslation()
  return (
    <section {...props}>
      <GridContainer>
        <StyledServicesContainer>
          <StyledTextWrapper>
            <StyledOverline>{t("Nasze usługi")}</StyledOverline>
            <StyledSlogan>{t("servicesSectionSlogan")}</StyledSlogan>
            <StyledText>{t("servicesSectionText")}</StyledText>
          </StyledTextWrapper>

          <StyledServicesBox>
            <ServiceBox
              service={t("Produkcja TV")}
              description={t("tvProductionDescription")}
            >
              <TvIcon />
            </ServiceBox>
            <ServiceBox
              service={t("Eventy")}
              description={t("eventsDescription")}
            >
              <EventsIcon />
            </ServiceBox>
            <ServiceBox
              service={t("Sprzęt")}
              description={t("equipmentDescription")}
            >
              <EquipmentIcon />
            </ServiceBox>
            <ServiceBox
              service={t("Esport & gaming")}
              description={t("esportDescription")}
            >
              <EsportIcon />
            </ServiceBox>
            <ServiceBox
              service={t("Produkcja video")}
              description={t("videoProductionDescription")}
            >
              <VideoIcon />
            </ServiceBox>
            <ServiceBox
              service={t("Marketing")}
              description={t("marketingDescription")}
            >
              <MarketingIcon />
            </ServiceBox>
          </StyledServicesBox>
        </StyledServicesContainer>
      </GridContainer>
    </section>
  )
}
