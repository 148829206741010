import React, { useContext } from "react"
import styled from "styled-components"
import { H2, Overline } from "../../shared/Typography/Typography"
import StaffMemberBox from "./StaffMemberBox"
import GridContainer from "../../Layout/GridContainer"
import { device } from "../../shared/styles/breakpoints"
import jedrzejSteszewskiImg from "../../../images/staffMembers/jedrzej-steszewski.png"
import hubertHabasImg from "../../../images/staffMembers/hubert-habas.png"
import estimateImg from "../../../images/staffMembers/estimate-img.png"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { LayoutContext } from "../../Layout/context/LayoutContext"


const staffMembers = [
  {
    imgUrl: jedrzejSteszewskiImg,
    imgAlt: "Jędrzej Stęszewski - Board Member",
    name: "Jędrzej Stęszewski",
    spec: "Board Member",
  },
  {
    imgUrl: hubertHabasImg,
    imgAlt: "Hubert Habas - Sales Director",
    name: "Hubert Habas",
    spec: "Sales Director",
  },
]

const StyledStaffMembersGrid = styled.div`
  margin: ${({ theme }) => `0 0  -${theme.spaces[244]}`};
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMembersBox = styled.div`
  display: flex;
  align-items: flex-end;
  height: 384px;
  gap: ${({ theme }) => theme.spaces[20]};
  width: 1020px;
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
  @media ${device.xs} {
    width: 886px;
  }
  @media ${device.xl} {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
  }
`

const StyledFutureText = styled(H2)`
  margin: ${({ theme }) => `0 auto ${theme.spaces[40]}`};
  white-space: pre-wrap;
  text-align: left;
  @media ${device.lg} {
    margin: ${({ theme }) => `0 auto ${theme.spaces[80]}`};
    text-align: center;
  }
`
const StyledText = styled(Overline)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]} `};
  display: block;
  @media ${device.lg} {
    text-align: center;
  }
`

const StaffMembersSection = styled.section``

const StyledMembersOverflow = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${device.xl} {
    overflow: hidden;
  }
`

const StyledEstimateBox = styled(StaffMemberBox)`
  transition: ${({ theme }) => theme.transition};
  &:hover {
    color: ${({ theme }) => theme.colors.red};
    cursor: pointer;
  }
`

export default function StaffMembersGrid({ ...props }) {
  const { t } = useTranslation()
  const { setOpenEstimateForm } = useContext(LayoutContext)
  return (
    <StaffMembersSection {...props}>
      <StyledStaffMembersGrid>
        <GridContainer>
          <StyledText>{t("staffMembersText")}</StyledText>
          <StyledFutureText>{t("staffMembersFutureText")}</StyledFutureText>
        </GridContainer>
        <StyledMembersOverflow>
          <StyledMembersBox>
            {staffMembers.map((el, key) => (
              <StaffMemberBox
                imgUrl={el.imgUrl}
                imgAlt={el.imgAlt}
                title={el.name}
                subtitle={el.spec}
                key={key}
              />
            ))}
            <StyledEstimateBox
              imgUrl={estimateImg}
              imgAlt={t("Wyceń projekt")}
              title={t("Wyceń projekt")}
              subtitle={t("Wyślij nam Brief!")}
              onClick={() => setOpenEstimateForm(true)}
            ></StyledEstimateBox>
          </StyledMembersBox>
        </StyledMembersOverflow>
      </StyledStaffMembersGrid>
    </StaffMembersSection>
  )
}
