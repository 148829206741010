import React from "react"
import styled from "styled-components"
import {
  SectionTitleBig,
  Disclaimer,
  Overline,
} from "../../shared/Typography/Typography"
import { StaticImage } from "gatsby-plugin-image"
import { device } from "../../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledHeroReco = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.spaces[32]} ${theme.spaces[16]}`};
  position: relative;
  box-sizing: border-box;
  width: 100%;
  @media ${device.lg} {
    padding: ${({ theme }) =>
      `${theme.spaces[40]} ${theme.spaces[10]} ${theme.spaces[40]} ${theme.spaces[40]}`};
    align-items: flex-end;
    max-width: 590px;
  }

  @media ${device.xl} {
    padding-right: ${({ theme }) => theme.spaces[40]};
    justify-self: end;
  }
`

const StyledHeroRecoGuy = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 40%;
  display: none;
  @media ${device.lg} {
    display: block;
    left: -20px;
  }
  @media ${device.xl} {
    left: 0;
  }
`

const StyledHeroRecoTextWrapper = styled.div`
  max-width: 328px;
`

const StyledHeroRecoTitle = styled(SectionTitleBig)`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`

const StyledHeroRecoDisclaimer = styled(Disclaimer)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`

const StyledOverline = styled(Overline)`
  display: block;
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]} 0`};
`

export default function HeroReco() {
  const { t } = useTranslation()
  return (
    <StyledHeroReco>
      <StyledHeroRecoGuy>
        <StaticImage
          src="../../../images/heroSection/hero-reco-guy.png"
          alt="a nice guy"
        />
      </StyledHeroRecoGuy>
      <StyledHeroRecoTextWrapper>
        <StyledOverline>{t("Klienci o nas")}</StyledOverline>
        <StyledHeroRecoTitle>{t("heroRecoTitle")}</StyledHeroRecoTitle>
        <StyledHeroRecoDisclaimer>
          {t("heroRecoDisclaimer")}
        </StyledHeroRecoDisclaimer>
      </StyledHeroRecoTextWrapper>
    </StyledHeroReco>
  )
}
