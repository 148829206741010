import React from "react"

export function TvIcon () {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 37.5v-18a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3h-3.258M16.5 37.5v-18h-5.3c-4.878 0-9.7 7.5-9.7 11.486V37.5a3 3 0 0 0 3 3h7.758" stroke="#0E1E1F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 42a4.501 4.501 0 0 0 8.657 1.722 4.501 4.501 0 0 0-8.315-3.444A4.501 4.501 0 0 0 12 42v0ZM31.5 42a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0v0ZM1.5 31.5h15M36.106 1.5a7.5 7.5 0 0 1-10.6 10.6l10.6-10.6ZM30.804 6.804 27 3M25.5 31.5v-9M22.5 22.5h6M34.5 22.5v3.056a8.082 8.082 0 0 0 3 5.944 8.083 8.083 0 0 0 3-5.944V22.5M20.742 40.5h11.014" stroke="#0E1E1F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
