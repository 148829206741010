import React from "react"

export function MarketingIcon() {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#marketingIcon)"
        stroke="#0E1E1F"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m6.264 25.6.896-2.35-2.092-5.488a1.858 1.858 0 0 1 .72-2.214l4.916-3.21 1.534-5.668a1.856 1.856 0 0 1 1.884-1.368l5.864.298 4.572-3.69a1.856 1.856 0 0 1 2.33 0L31.46 5.6l5.864-.292a1.856 1.856 0 0 1 1.884 1.368l1.534 5.668 4.916 3.21a1.856 1.856 0 0 1 .72 2.214l-2.09 5.488 2.09 5.486a1.858 1.858 0 0 1-.72 2.216l-4.916 3.2-1.542 5.67a1.854 1.854 0 0 1-1.884 1.37l-5.864-.292-4.572 3.682M1.5 39.538l3.312 5.218M19.816 16.294l12.588 19.832" />
        <path d="M31.742 35.082 4.15 43.714l-1.988-3.132 18.316-23.244 11.264 17.744Z" />
        <path d="m10.111 41.848 1.752 2.692a4.083 4.083 0 0 0 5.812 1.228 4.512 4.512 0 0 0 1.174-6.08l-.326-.472" />
      </g>
      <defs>
        <clipPath id="marketingIcon">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
