import React from 'react'

export function PlayIcon () {
  return (
    <svg width="36" height="38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m18 1 10.58 3.438 6.539 9v11.124l-6.539 9L18 37 7.42 33.562l-6.539-9V13.438l6.539-9L18 1Z" fill="#fff" fillOpacity=".3" stroke="#fff" />
      <path d="M24.5 18.134a1 1 0 0 1 0 1.732l-9 5.196a1 1 0 0 1-1.5-.866V13.804a1 1 0 0 1 1.5-.866l9 5.196Z" fill="#fff" />
    </svg>
  )
}
