import React from "react"
import styled from "styled-components"
import { device } from "../../shared/styles/breakpoints"
import {
  ParagraphRegular,
  H4,
  ParagraphBold,
} from "../../shared/Typography/Typography"

export const StyledMember = styled.div`
  width: 240px;
  height: 100%;
  border: 1px solid #f1f1f1;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${device.xs} {
    width: 280px;
  }

  @media ${device.xl} {
    width: 25%;
    max-width: 280px;
    box-sizing: border-box;
  }
`
export const StyledMiniature = styled.img`
  width: 100%;
  max-height: 280px;
  object-fit: cover;
  object-position: top;
  background-color: #f5f0f6;
  display: block;
`
export const StyledFirstLine = styled(H4)`
  margin: 0;
`
export const StyleThirdLine = styled(ParagraphBold)``
export const StyledSecondLine = styled(ParagraphRegular)`
  margin: 0;
  white-space: pre-wrap;
`

const StyledTextWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spaces[24]} ${theme.spaces[16]}`};
`

export default function StaffMemberBox({
  imgUrl,
  imgAlt,
  title,
  subtitle,
  children,
  ...props
}) {
  return (
    <StyledMember {...props}>
      <StyledMiniature src={imgUrl} alt={imgAlt}></StyledMiniature>
      <StyledTextWrapper>
        <StyledFirstLine>{title}</StyledFirstLine>
        <StyledSecondLine>{subtitle}</StyledSecondLine>
        {children}
      </StyledTextWrapper>
    </StyledMember>
  )
}
