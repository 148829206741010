import React from "react"
import styled from "styled-components"
import { ParagraphRegular, H4 } from "../../shared/Typography/Typography"
import { device } from "../../shared/styles/breakpoints"

export const StyledService = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`
export const StyledFirstLine = styled(H4)`
  margin: ${({ theme }) => `${theme.spaces[24]} 0 ${theme.spaces[8]}`};
`

export const StyledSecondLine = styled(ParagraphRegular)`
  white-space: pre-wrap;
  margin: 0;
`
const StyledTextWrapper = styled.div`
  @media ${device.lg} {
    padding: ${({ theme }) =>
      `${theme.spaces[40]} ${theme.spaces[19]} ${theme.spaces[16]} ${theme.spaces[40]}`};
  }
`

export default function ServiceBox({ service, description, children }) {
  return (
    <StyledService>
      <StyledTextWrapper>
        {children}
        <StyledFirstLine>{service}</StyledFirstLine>
        <StyledSecondLine>{description}</StyledSecondLine>
      </StyledTextWrapper>
    </StyledService>
  )
}
