import React from 'react'
import styled from 'styled-components'
import HeroText from './HeroText'
import HeroReco from './HeroReco'
import HeroVideo from './HeroVideo'
import { device } from '../../shared/styles/breakpoints'
import heroBg from '../../../images/heroSection/hero-bg.png'
const StyledHeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.lg} {
    display: grid;
    grid-template-columns: minmax(50%, 590px) minmax(50%, 50vw);
    grid-template-rows: 1fr auto;
    justify-content: end;
  }
  @media ${device.xl} {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      left: calc(50% + 6px);
      bottom: -17px;
      width: 1007px;
      height: 786px;
      background-image: url(${heroBg});
      background-repeat: no-repeat;
    }
  }
`

export default function HeroSection () {
  return (
    <StyledHeroSection>
      <HeroText />
      <HeroVideo />
      <HeroReco />
    </StyledHeroSection>
  )
}
