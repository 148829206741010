import React from 'react'
import styled from 'styled-components'
import { device } from '../../shared/styles/breakpoints'

const StyledSlideGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;

  @media ${device.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.md} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${device.lg} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const StyledClientLogo = styled.img`
  text-align: center;
  margin: ${({ theme }) => `${theme.spaces[16]} auto`};
`

export default function ClientSliderItem ({ clientsLogos }) {
  return (
    <StyledSlideGrid>
      {
        clientsLogos.map((clientLogo, key) => (
          <StyledClientLogo src={clientLogo.src} alt={clientLogo.alt} key={key} />
        ))
      }
    </StyledSlideGrid>
  )
}
