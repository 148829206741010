import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { PlayIcon } from "../../shared/Icons/PlayIcon"
import { FileIcon } from "../../shared/Icons/FileIcon"
import { CloseIcon } from "../../shared/Icons/CloseIcon"
import { device } from "../../shared/styles/breakpoints"

const StyledVideoWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  @media ${device.lg} {
    padding-top: ${({ theme }) => theme.spaces[52]};
    height: 100%;
  }
`

const StyledVideoThumbWrapper = styled.div`
  box-sizing: border-box;
  > * {
    width: 100%;
    max-height: 450px;
  }
  @media ${device.lg} {
    height: 100%;
    * {
      height: 100%;
      max-width: 830px;
      max-height: 553px;
    }
  }
`

const StyledButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  @media ${device.lg} {
    max-width: 590px;
    width: 100%;
    left: 0;
    right: auto;
    text-align: right;
  }
`

const StyledPlayButton = styled.button`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.yellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow};
  padding: ${({ theme }) => theme.spaces[22]};
  transition: ${({ theme }) => theme.transition};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.yellow300};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.yellow};
    border-color: ${({ theme }) => theme.colors.yellow700};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.yellow700};
    border-color: ${({ theme }) => theme.colors.yellow700};
  }
`

const StyledFileIconWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.black};
  width: 80px;
  height: 80px;
  right: 0;
  top: 100%;
  display: none;
  @media ${device.lg} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyledVideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => `${theme.colors.black}EF`};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  @media ${device.lg} {
    align-items: center;
  }
`

const StyledIframe = styled.iframe`
  max-width: 1280px;
  width: 100%;
  height: calc(100% - 72px);
  display: block;
  box-sizing: border-box;
  border: none;
  @media (orientation: landscape) {
    height: 100%;
  }
  @media ${device.lg} {
    max-height: 740px;
    height: calc(100vh - 140px);
  }
  @media ${device.xl} {
    padding: ${({ theme }) => theme.spaces[24]};
  }
`

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces[24]};
  transition: ${({ theme }) => theme.transition};
  > svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.yellow};
  }
`

export default function HeroVideo () {
  const [isOpenVideo, setOpenVideo] = useState(false)

  const handleClickOverlay = e => {
    e.stopPropagation()
    if (e.target === e.currentTarget) {
      setOpenVideo(false)
    }
  }
  return (
    <StyledVideoWrapper>
      <StyledVideoThumbWrapper>
        <StaticImage
          src="../../../images/heroSection/video-thumb.png"
          alt="aaa"
        />
      </StyledVideoThumbWrapper>
      <StyledButtonWrapper>
        <StyledPlayButton onClick={() => setOpenVideo(true)}>
          <PlayIcon />
        </StyledPlayButton>
        <StyledFileIconWrapper>
          <FileIcon />
        </StyledFileIconWrapper>
      </StyledButtonWrapper>
      {isOpenVideo && (
        <StyledVideoOverlay onClick={handleClickOverlay}>
          <StyledIframe width="560" height="315" src="https://www.youtube.com/embed/121BT4c5PoY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></StyledIframe>
          <StyledCloseButton onClick={() => setOpenVideo(false)}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledVideoOverlay>
      )}
    </StyledVideoWrapper>
  )
}
